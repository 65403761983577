@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Manrope:wght@200..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  animation: 3s fadeIn;
}

.outer {
  margin: 0 auto;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.other-app {
  margin: 10px 3% 50px 3%;
  padding: 10px 0 10px 0;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
}

.nav {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0px 10px 20px 5px;
  text-transform: uppercase;
  color: #a19c96;
  font-weight: 500;
}

.logo {
  font-size: 1rem;
  font-weight: 700;
  color: #a19c96;
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  text-transform: uppercase;
}

.social {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #a19c96;
}

.social a {
  transition: 1s;
}

.social a:hover {
  color: #f3eee7;
}

.nav button {
  text-transform: uppercase;
  border: none;
  font-weight: 100;
  font-size: 1rem;
  font-family: inherit;
  cursor: pointer;
  margin-right: 5px;
  background-color: inherit;
  color: rgb(209, 203, 196);
  transition: 1s;
}

.nav button:hover {
  font-weight: 600;
}

.portfolio {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.booking .portfolio-head {
  margin-right: 0px;
}

.portfolio-head {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-bottom: 10vh;
  max-width: 700px;
}

.portfolio-head h1 {
  font-family: "Playfair Display", serif;
  color: #6d6964;
  padding: 60px 0;
  margin-top: 30px;
}

a {
  color: inherit;
  text-decoration: none;
}

.portfolio h2 {
  font-weight: 200;
  font-style: italic;
  font-family: "Playfair Display", serif;
  color: #6d6964;
}

.portfolio-head p {
  color: #a19c96;
  padding: 30px 60px;
}

.portfolio-image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  max-width: 950px;
  justify-content: center;
}

.portfolio img {
  object-fit: cover;
  animation: 3s fadeIn;
}

.home-nav {
  margin-top: -42px;
}

@media screen and (max-width: 526px) {
  .home-nav {
    margin-top: 0px;
  }
}

@media screen and (min-width: 977px) {
  .home-nav {
    margin-top: -240px;
  }
}

.hero-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.hero {
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  /* background-image: url(./hero.jpg);*/
  color: #fff;
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 120vh;
  margin-bottom: 50px;
  animation: fadeIn 5s;
  /* margin-right: 40px; */
}

.hero-logo {
  width: 50%;
  padding: 20px 0px;
  text-align: center;
}

.hero-logo p {
  margin-top: 20px;
  font-family: "EB Garamond", serif;
  font-style: italic;
}

.hero-logo img {
  width: 100%;
}

.hero-logo-bottom {
  border-top: 1px solid white;
  padding-top: 20px;
  font-size: 1.3rem;
}

.hero-logo-bottom p {
  margin-top: 0 !important;
}

@media screen and (min-width: 1300px) {
  .hero-logo {
    width: 40%;
  }
  .hero-logo-bottom {
    font-size: 1.5rem;
  }
  .portfolio-image-container {
    margin-left: 100px;
  }
}

@media screen and (max-width: 900px) {
  .hero-logo {
    width: 60%;
  }
}

@media screen and (max-width: 700px) {
  .hero-logo {
    width: 70%;
  }
  .hero-logo-bottom {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 550px) {
  .hero-logo {
    width: 90%;
  }
  .hero-logo-bottom {
    font-size: 1rem;
  }
}

.hero-header-container {
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  justify-content: center;
}

.logo-container {
  display: flex;
  flex-direction: column;
}

.hero-header-container img {
  width: 40%;
}

.hero h1 {
  max-width: 700px;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-size: 4rem;
  line-height: 3.5rem;
  text-transform: uppercase;
  padding: 5px;
}

.recent-photos-container {
  height: 6.5vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1;

  transition: 3s;
}

.recent-photos-container::-webkit-scrollbar {
  display: none;
}

.open {
  height: 50vh;
}

.recent-photos {
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 10px;
  display: flex;
  flex-direction: row;
}

.set {
  font-family: "Rubik", sans-serif;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-right: 20px;
}

.set-heading {
  width: 100px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.set h2 {
  font-weight: 400;
  font-size: 1.5rem;
  color: #c3bbb1;
}

.set p {
  width: 100%;
  font-weight: 200;
  font-size: 1rem;
  color: #c3bbb1;
}

.set img {
  object-fit: cover;
  height: 38vh;
}

.recent-photos-button {
  background-color: transparent;
  color: #c3bbb1;
  border-top: 2px solid #c3bbb1;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 1.1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  height: 7vh;
  border-bottom: 2px solid #c3bbb1;
}

.recent-photos-button:hover {
  cursor: pointer;
}

.recent-photos-button p:hover {
  background-color: #f3eee7;
  cursor: pointer;
}

.recent-photos-button p {
  border-left: 2px solid #c3bbb1;
  padding: 15px 30px;
  width: 90%;
}

.arrow {
  margin: 0px 30px;
  width: 10px;
  height: 10px;
  border-right: 2px solid #c3bbb1;
  border-bottom: 2px solid #c3bbb1;
}

.down {
  margin-top: 18px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.up {
  margin-top: 22px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.whimsy {
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: fadeIn 5s;
}

.whimsy img {
  width: 100%;
  overflow: hidden;
}

.whimsy h2 {
  display: none;
  width: 20%;
  max-width: 640px;
  padding-left: 8%;
  font-family: "Hedvig", serif;
  font-weight: 200;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 30px;
  color: #6d6964;
}

.blurb {
  width: 100%;
  text-align: center;
  height: 50vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.blurb h2 {
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 30px;
  color: #6d6964;
}

.blurb h3 {
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 30px;
  color: #6d6964;
  font-style: italic;
}

.blurb p {
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: normal;
  margin-bottom: 30px;
  margin-top: 3vh;
  color: #6d6964;
}

@media screen and (min-width: 720px) {
  .blurb p {
    width: 60%;
  }
}

.blurb button {
  width: 40%;
  background-color: #a19c96;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 10px;
  text-transform: uppercase;
}

.mini-me {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 14% 0;
}

.mini-me img {
  width: 37%;
  height: auto;
  border-radius: 150px;
  height: 550px;
  object-fit: cover;
  margin-right: 8%;
  max-width: 280px;
}

.mini-me p {
  width: 45%;
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: normal;
  margin-bottom: 30px;
  margin-top: 3vh;
  color: #6d6964;
  max-width: 380px;
}

.small-duo-container {
  width: 100%;
  padding: 10px 10px 50px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.small-duo-container img {
  width: 50%;
  height: 65vh;
  object-fit: cover;
}

.small-col-container::-webkit-scrollbar {
  display: none;
}

.small-col-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100%;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.small-col-container img {
  width: 30%;
  height: 65vh;
  object-fit: cover;
}

@media screen and (min-wdith: 700px) {
  .small-col-container img {
    width: 100%;
  }
}

.about-me {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.about-main {
  width: 100%;
  object-fit: cover;
  height: 60vh;
  margin: 90px 0;
  animation: 3s fadeIn;
  overflow: clip;
}

.about-main img {
  object-fit: cover;
}

.column-shots {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 2%;
  width: 100%;
  transition: 2s;
  height: 80vh;
}

.column-shots img {
  /* width: 32%; */
  width: 100%;
  object-fit: cover;
  margin-bottom: 90px;
}

.about-me h1 {
  font-family: "Playfair Display", serif;
  color: #6d6964;
  padding: 60px 0;
  margin-top: 30px;
  max-width: 700px;
}

.about-me h2 {
  font-weight: 200;
  font-style: italic;
  font-family: "Playfair Display", serif;
  color: #6d6964;
  max-width: 700px;
}

.about-me p {
  color: #a19c96;
  padding: 30px 60px;
  max-width: 700px;
}

.about-me img {
  width: 100%;
  object-fit: cover;
  margin-top: 90px;
  margin-bottom: 90px;
}

.booking {
  width: 100%;
  padding: 0px 0px 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

textarea:focus,
input:focus {
  outline: none;
}

form {
  width: 100%;
  margin-top: 10px;
  max-width: 500px;
}

.booking input {
  font-family: monospace;
  border: 3px solid #c3bbb1;
  background-color: transparent !important;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  color: #c3bbb1;
}

.booking h2 {
  font-weight: 200;
  font-style: italic;
  font-family: "Playfair Display", serif;
  color: #6d6964;
}

::placeholder {
  color: #a19c96;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #a19c96;
}

textarea {
  border: 3px solid #c3bbb1;
  color: #a19c96;
  font-family: monospace;
  background-color: transparent;
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  height: 150px;
  padding: 10px;
}

.booking button {
  width: 100%;
  font-family: monospace;
  padding: 10px 50px;
  background-color: transparent;
  color: #c3bbb1;
  border: 3px solid #c3bbb1;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.5rem;
}

.booking button:hover {
  background-color: #f3eee7;
  cursor: pointer;
}

.book-home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  text-align: center;
}

.book-home h2 {
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 1.13rem;
  margin-bottom: 20px;
  color: #6d6964;
}

.book-home p {
  width: 100%;
  font-family: "EB Garamond", serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: normal;
  margin-bottom: 30px;
  margin-top: 3vh;
  color: #6d6964;
  max-width: 584px;
}

.book-home button {
  padding: 10px 50px;
  background-color: inherit;
  color: #c3bbb1;
  border: none;
  border: 2px solid #c3bbb1;
  text-transform: uppercase;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
}

.book-home button:hover {
  background-color: #f3eee7;
  cursor: pointer;
}

footer {
  margin: 10px 3% 0px 3%;
  color: white !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 2% 5%;
  border-radius: 4px;
  height: 40vh;
  margin-top: 6vh;
}

@media screen and (min-width: 1150px) {
  .hero {
    background-position: center 30%;
  }
}

@media screen and (min-width: 1250px) {
  footer {
    background-position: 0% 62%;
  }
}

.footer-column {
  width: 30%;
}

.mailer-container form {
  width: 100%;
  margin-top: 0px;
}

.mailer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-right: 5%;
  max-width: 280px;
}

.mailer-container h1 {
  font-family: "Playfair Display", serif;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-style: italic;
}

.mailer-container input {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  margin: 10px 0;
  padding: 5px 0;
  color: white;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
}

.mailer-container input:focus {
  outline: none;
}

.mailer-container input::placeholder {
  color: white;
}

.mailer-container button {
  width: 100%;
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 5px;
  text-transform: uppercase;
  margin-top: 5px;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Rubik", sans-serif;
}

.mailer-container button:hover {
  background-color: white;
  color: #c3bbb1;
  opacity: 0.7;
  cursor: pointer;
}

.mailer-container p {
  padding-top: 5px;
  font-family: "Rubik", san-serif;
  font-weight: 100;
  font-size: 0.8rem;
  color: white;
}

.footer-menu {
  width: 100%;
  padding-right: 3%;
  margin-bottom: 20px;
}

footer h2 {
  font-family: "Rubik", san-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

footer p {
  font-family: "EB Garamond", serif;
  font-size: 1.1rem;
}

.get-in-touch {
  width: 100%;
}

footer h3 {
  font-family: "Playfair Display", serif;
  font-style: italic;
  font-weight: 500;
  font-size: 1.2rem;
}

.hidden-col {
  display: none;
}

.link {
  font-style: italic;
  color: #c3bbb1;
}

@media screen and (min-width: 1750px) {
  .portfolio-head {
    max-width: 560px;
  }
}

@media screen and (min-width: 600px) {
  .hidden {
    display: block;
    opacity: 0.5;
  }

  .App {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 900px) {
  .footer-column {
    width: 100%;
  }
}

@media screen and (min-width: 500px) {
  .hidden-col {
    display: block;
    opacity: 0.5;
  }

  .column-shots img {
    width: 32%;
  }
}

@media screen and (max-width: 500px) {
  .nav-bar {
    flex-direction: column;
    align-items: center;
  }

  .about-me p {
    padding: 30px 0px;
  }

  .portfolio-head p {
    padding: 30px 0px;
  }
}

@media screen and (max-width: 700px) {
  .mini-me {
    flex-direction: column;
  }

  .small-col-container {
    overflow: scroll;
  }

  .small-col-container img {
    width: 100%;
  }

  .mini-me img {
    width: 75%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    margin: 0;
    margin-top: -145px;
    height: 270px;
    border: 8px solid #fff;
  }

  .mini-me p {
    margin-top: 38px;
    width: 75%;
  }

  footer {
    display: block;
    height: auto;
  }

  .mailer-container {
    width: 100%;
    padding: 0 0 35px 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

footer {
  margin-top: 13vh;
  color: #c3bbb1;
  overflow: hidden;
  font-family: "Rubik", sans-serif;
}

/*potentially add a class for each type of photoshoot and style a hover state of italic. Later though. Final touches.*/

/*portfolio page gets a layout of photos all on different fade in delay times */
